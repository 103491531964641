import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'ranking',
    loadChildren: () => import('./pages/ranking/ranking.module').then(m => m.RankingModule)
  },
  {
    path: 'calculadora',
    loadChildren: () => import('./pages/calculadora/calculadora.module').then(m => m.CalculadoraModule)
  },
  {
    path: 'vivo',
    loadChildren: () => import('./pages/vivo/vivo.module').then(m => m.VivoModule)
  },
  {
    path: 'social',
    loadChildren: () => import('./pages/social/social.module').then(m => m.SocialModule)
  },
  {
    path: 'noticias',
    loadChildren: () => import('./pages/noticias/noticias.module').then(m => m.NoticiasModule)
  },
  {
    path: 'anteriores',
    loadChildren: () => import('./pages/anteriores/anteriores.module').then(m => m.AnterioresModule)
  },  
  {
    path: 'colles',
    loadChildren: () => import('./pages/colles/colles.module').then(m => m.CollesModule)
  },
  {
    path: 'normativa',
    loadChildren: () => import('./pages/normativa/normativa.module').then(m => m.NormativaModule)
  },
  {
    path: 'puntuacion',
    loadChildren: () => import('./pages/puntuacion/puntuacion.module').then(m => m.PuntuacionModule)
  },
  {
    path: 'patrocinadores',
    loadChildren: () => import('./pages/patrocinadores/patrocinadores.module').then(m => m.PatrocinadoresModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'ranking-single',
    loadChildren: () => import('./pages/ranking-single/ranking-single.module').then( m => m.RankingSinglePageModule)
  },
  {
    path: 'colles-single',
    loadChildren: () => import('./pages/colles-single/colles-single.module').then( m => m.CollesSinglePageModule)
  },
  {
    path: 'noticias-single',
    loadChildren: () => import('./pages/noticias-single/noticias-single.module').then( m => m.NoticiasSinglePageModule)
  },
  {
    path: 'ediciones-single',
    loadChildren: () => import('./pages/ediciones-single/ediciones-single.module').then( m => m.EdicionesSinglePageModule)
  },
  {
    path: 'modal-puntuacion',
    loadChildren: () => import('./pages/modal-puntuacion/modal-puntuacion.module').then( m => m.ModalPuntuacionPageModule)
  },
  {
    path: 'modal-confirmacion',
    loadChildren: () => import('./pages/modal-confirmacion/modal-confirmacion.module').then(m => m.ModalConfirmacionPageModule)
  },
  {
    path: 'calculadora-seleccion',
    loadChildren: () => import('./pages/calculadora-seleccion/calculadora-seleccion.module').then( m => m.CalculadoraSeleccionPageModule)
  },
  {
    path: 'seleccionar-castell',
    loadChildren: () => import('./pages/seleccionar-castell/seleccionar-castell.module').then( m => m.SeleccionarCastellPageModule)
  },
  {
    path: 'seleccionar-configuracion',
    loadChildren: () => import('./pages/seleccionar-configuracion/seleccionar-configuracion.module').then( m => m.SeleccionarConfiguracionPageModule)
  },
  {
    path: 'seleccionar-resultado',
    loadChildren: () => import('./pages/seleccionar-resultado/seleccionar-resultado.module').then( m => m.SeleccionarResultadoPageModule)
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],//[RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
